<template>
  <!-- 履行可能データ提供契約一覧 -->
  <b-container class="py-4">
    <h1>履行可能データ提供契約</h1>
    <b-card no-body>
      <b-card-body>
        <b-card-title title-tag="h2">
          PCDコード発行・データダウンロード
        </b-card-title>
        <b-form-row>
          <b-col offset-sm="8" sm="4" offset-lg="10" lg="2">
            <b-button
              variant="outline-csub"
              class="mb-3 w-100"
              v-on:click="getProposalList()"
            >
              一覧更新
            </b-button>
          </b-col>
        </b-form-row>
        <p v-if="isProcessing" class="text-caccent text-center">
          <b-icon icon="circle-fill" animation="throb"></b-icon>PCDコード発行中
        </p>
        <b-alert show v-if="message" variant="csub" dismissible
          >{{ message }}
        </b-alert>
        <base-table
          v-bind:items="proposalList"
          v-bind:fields="proposalListFields"
          v-bind:is-filter="true"
          v-bind:is-pagination="true"
          v-bind:default-sort="defaultSort"
        >
          <template v-slot:pcdIssueButton="row">
            <b-button
              variant="csub"
              size="sm"
              class="w-100"
              v-bind:disabled="!!row.item.expireAt"
              v-on:click.stop="issuePcdCode(row.item)"
            >
              発行
            </b-button>
          </template>
          <template v-slot:downloadButton="row">
            <span v-if="row.item.pcdStatus == 1">準備中</span>
            <span v-else-if="row.item.pcdStatus == 2">
              <b-button
                variant="caccent"
                size="sm"
                class="w-100"
                v-on:click.stop="downloadData(row.item)"
              >
                ダウンロード
              </b-button>
            </span>
            <span v-else>ー</span>
          </template>
        </base-table>
        <div class="text-center">
          <p v-if="isGettingList" class="text-caccent">
            <b-icon icon="circle-fill" animation="throb"></b-icon>
            データ提供契約一覧取得中
          </p>
          <p v-else-if="getListError" class="text-danger">
            データ提供契約一覧の取得に失敗しました。
          </p>
          <p v-else-if="proposalList.length == 0" class="text-csub">
            履行可能なデータ提供契約はありません。
          </p>
        </div>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import BaseTable from '@/components/common/BaseTable'
import { convertTimezone } from '@/mixins/utility'
export default {
  components: {
    BaseTable,
  },
  mixins: [convertTimezone],
  data() {
    return {
      proposalList: [],
      proposalListFields: [
        { key: 'supplyProposalId', label: '契約ID', tdClass: 'text-center' },
        { key: 'title', label: '契約名' },
        {
          key: 'recruitmentEndDate',
          label: '募集終了',
          tdClass: 'text-center',
        },
        { key: 'fulfilmentDate', label: '履行日', tdClass: 'text-center' },
        {
          key: 'pcdIssueButton',
          label: 'PCDコード発行',
          tdClass: 'text-center',
          slotName: 'pcdIssueButton',
        },
        { key: 'expireAt', label: 'ダウンロード期限', tdClass: 'text-center' },
        {
          key: 'downloadButton',
          label: 'ダウンロード',
          tdClass: 'text-center',
          slotName: 'downloadButton',
        },
      ],
      defaultSort: { by: 'supplyProposalId', desc: true },
      isGettingList: false,
      getListError: false,
      isProcessing: false,
      message: null,
    }
  },
  created() {
    this.getProposalList()
  },
  methods: {
    getProposalList() {
      // データ提供契約一覧取得
      console.log('** get proposal list **')
      this.isGettingList = true
      this.getListError = false
      this.proposalList = []
      this.message = null
      this.$axiosDC
        .post('/dc-consumer/get_proposallist')
        .then((response) => {
          console.log(response)
          this.proposalList = response.data.proposalList
          // ダウンロード期限をLocalTimeに変換
          for (let i = 0; i < this.proposalList.length; i++) {
            this.proposalList[i].expireAt = this.convertTimezone(
              this.proposalList[i].expireAt,
              false
            )
          }
        })
        .catch((error) => {
          console.log(error)
          this.getListError = true
        })
        .finally(() => {
          this.isGettingList = false
        })
    },
    issuePcdCode(targetItem) {
      // PCDコード発行
      console.log('** issue pcd code **', targetItem)
      this.message = null
      this.isProcessing = true
      this.$axiosDC
        .post('/dc-consumer/data_preparation', {
          supplyProposalId: targetItem.supplyProposalId,
        })
        .then((response) => {
          console.log(response)
          // リスト項目更新
          targetItem.expireAt = this.convertTimezone(
            response.data.expireAt,
            false
          )
          targetItem.pcdStatus = response.data.pcdStatus
          targetItem.pcdCode = response.data.pcdCode

          this.message = `PCDコード発行が完了しました。ダウンロードの準備が完了するまでお待ちください。\n${targetItem.supplyProposalId} ${targetItem.title} 【PCDコード:${targetItem.pcdCode}】`
        })
        .catch((error) => {
          console.log(error)
          alert('PCDコード発行に失敗しました。')
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    downloadData(targetItem) {
      // データダウンロード
      console.log('** download data **', targetItem)
      this.message = null
      this.$axiosDC
        .post(
          '/dc-consumer/get_datafile',
          { pcdCode: targetItem.pcdCode },
          { responseType: 'blob' }
        )
        .then((response) => {
          console.log(response)
          const fileName = `${targetItem.pcdCode}.zip`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)

          this.message = `ダウンロードが完了しました。${targetItem.supplyProposalId} ${targetItem.title}`
        })
        .catch((error) => {
          console.log(error)
          alert('ダウンロードに失敗しました。')
        })
    },
  },
}
</script>
